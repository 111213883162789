import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout title={'404 Not Found'} subtitle={'HRBC'}>
    <div className="about-heading-content py-5">
      <div className="row">
        <div className="col-xl-9 col-lg-10 mx-auto">
          <div className="bg-faded rounded p-5">
            <h2 className="section-heading text-center mb-4">
              <span className="section-heading-lower">Page Not Found</span>
            </h2>
            <div className="row">
              <p>"What man of you, having a hundred sheep, if he has lost one of them, does not leave the ninety-nine in the open country, and go after the one that is lost, until he finds it? And when he has found it, he lays it on his shoulders, rejoicing."</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
